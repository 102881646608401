<script
    lang="ts"
    setup
>
    import type { OperatorOfflineStatus } from '~/ts/types/operator'
    import { useUserStore } from '~/stores/user'

    type Props = {
        inNavbar?: boolean
    }

    const props = defineProps<Props>()

    const popoverRef = ref<ReturnType<typeof defineComponent>>()

    const userStore = useUserStore()

    const inputModel = ref<OperatorOfflineStatus>(userStore.currentOperator.offline_status)
    const pending = ref<boolean>(false)

    userStore.onOperatorOfflineStatusChanged((value) => {
        inputModel.value = value
    })

    const { t } = useLang()

    const tooltipText = computed<string>(() => t(`work-status-value-${ inputModel.value }-description`))
    const popoverClass = computed<string>(() => {
        return props.inNavbar
            ? 'min-w-[220px] !left-auto !right-0 !top-full !bottom-auto px-2'
            : 'min-w-[220px] !left-[130%] !bottom-full px-2'
    })

    const toggleInputModel = (): void => {
        if (pending.value) {
            return
        }

        if (!userStore.isOperatorOnline) {
            inputModel.value = null

            updateStatus(null)

            return
        }

        popoverRef.value.toggle()
    }

    const selectOption = (option: OperatorOfflineStatus): void => {
        updateStatus(option)

        // Затримка для коректного приховання поповеру
        setTimeout(() => {
            inputModel.value = option
        }, 120)
    }

    const updateStatus = debounceFn(async (value: OperatorOfflineStatus) => {
        if (userStore.currentOperator.offline_status === value) {
            return
        }

        pending.value = true

        const { data, error } = await useApi().operator.updateOfflineStatus({
            operatorId: userStore.currentOperator.id,
            data: {
                offline_status: value
            }
        })

        if (error.value) {
            pending.value = false

            return
        }

        userStore.currentOperator.offline_status = data.value.offline_status
        userStore.currentOperator.offline_status_updated_at = data.value.offline_status_updated_at

        nextTick(() => userStore.handleOperatorOfflineStatus())

        pending.value = false
    }, 500)
</script>

<template>
    <TheSidebarPopover
        ref="popoverRef"
        :in-navbar="props.inNavbar"
        :popover-class="popoverClass"
    >
        <template #activator="{ active: popoverActive }">
            <TheSidebarActionWorkStatusActivator
                :in-navbar="props.inNavbar"
                :popover-active="popoverActive"
                :is-operator-online="userStore.isOperatorOnline"
                :pending="pending"
                :input-model="inputModel"
                @toggle-input-model="toggleInputModel"
            >
                {{ tooltipText }}
            </TheSidebarActionWorkStatusActivator>
        </template>

        <template #default="{ close: closePopover }">
            <div class="flex flex-col gap-1 w-full">
                <TheSidebarActionWorkStatusSectionSchedule :operator-schedule="userStore.currentOperator.schedule"/>

                <AppDivider class="!my-1"/>

                <TheSidebarActionWorkStatusSectionStatus
                    :input-model="inputModel"
                    :in-navbar="props.inNavbar"
                    @select-option="selectOption"
                    @close-popover="closePopover"
                />
            </div>
        </template>
    </TheSidebarPopover>
</template>
