<script
    lang="ts"
    setup
>
    type Props = {
        inNavbar?: boolean
        popoverClass?: string
    }

    type Emit = {
        (event: 'opened'): void
        (event: 'closed'): void
    }

    const props = withDefaults(defineProps<Props>(), {
        isNavbar: false,
        popoverClass: ''
    })
    const emit = defineEmits<Emit>()

    const activatorRef = ref<ReturnType<typeof defineComponent>>()
    const popoverRef = ref<ReturnType<typeof defineComponent>>()

    onClickOutside(
        popoverRef,
        () => (showPopover.value = false),
        { ignore: [ activatorRef ] }
    )

    const showPopover = ref<boolean>(false)

    const popoverClass = computed<string>(() => {
        return `
            z-[10]
            absolute
            flex
            flex-col
            items-start
            left-[120%]
            bottom-2.5
            p-4
            bg-white
            rounded-[8px]
            shadow-2xl
            origin-bottom-left
            ${ props.popoverClass }
        `
    })

    const open = (): void => {
        if (showPopover.value) {
            return
        }

        showPopover.value = true

        emit('opened')
    }

    const close = (): void => {
        if (!showPopover.value) {
            return
        }

        showPopover.value = false

        emit('closed')
    }

    const toggle = (): void => showPopover.value ? close() : open()

    const popoverBeforeEnter = (el) => {
        el.classList.add('vanishing-specific')

        el.style.overflow = 'hidden'
        el.style.opacity = 0
        el.style.transform = `scale(0.2) translateX(${ -20 }px)`

        if (props.inNavbar) {
            el.style.transformOrigin = 'right top'
        }
    }

    const popoverEnter = (el, done) => {
        animate({
            duration: 0.26,
            timing: animationTiming.makeEaseOut(animationTiming.quad),
            draw(progress) {
                el.style.opacity = progress
                el.style.transform = `scale(${ progress }) translateX(${ (progress * 20) - 20 }px)`
            },
            onComplete() {
                el.style.overflow = null
                el.style.opacity = null
                el.style.transform = null
                el.style.transformOrigin = null

                el.classList.remove('vanishing-specific')

                done()
            }
        })
    }

    const popoverBeforeLeave = (el) => {
        el.classList.add('vanishing-specific')
    }

    const popoverLeave = (el, done) => {
        animate({
            duration: 0.08,
            draw(progress) {
                el.style.opacity = 1 - progress
            },
            onComplete() {
                el.style.opacity = null

                el.classList.remove('vanishing-specific')

                done()
            }
        })
    }

    defineExpose({
        toggle,
        open,
        close
    })
</script>

<template>
    <div class="relative">
        <div ref="activatorRef">
            <slot
                name="activator"
                :active="showPopover"
                :toggle="toggle"
            />
        </div>

        <Transition
            :css="false"
            @before-enter="popoverBeforeEnter"
            @enter="popoverEnter"
            @before-leave="popoverBeforeLeave"
            @leave="popoverLeave"
        >
            <div
                v-if="showPopover"
                key="popover"
                ref="popoverRef"
                :class="popoverClass"
            >
                <slot
                    :active="showPopover"
                    :close="close"
                />
            </div>
        </Transition>
    </div>
</template>
