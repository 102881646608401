<script
    lang="ts"
    setup
>
    import { useUserStore } from '~/stores/user'
    import createSidebarTipsContext from '~/helpers/createSidebarTipsContext'

    type Emit = {
        (event: 'close'): void
    }

    const emit = defineEmits<Emit>()

    const userStore = useUserStore()

    const { tips, progressValue } = createSidebarTipsContext()

    const progressLineStyle = computed<string>(() => {
        return '--tips-progress-line-width:' + progressValue.value
    })
</script>

<template>
    <div class="flex flex-col gap-4">
        <div class="text-[20px] font-medium leading-[24px]">
            {{ $t('sidebar-onboarding-1') }}
        </div>

        <div class="flex flex-col">
            <div class="flex items-center justify-between text-[14px] font-medium leading-[17px]">
                <div>
                    {{ $t('your-progress') }}
                </div>

                <div>
                    {{ progressValue }}
                </div>
            </div>

            <div
                class="
                    overflow-hidden
                    flex
                    h-2
                    mt-2
                    bg-[#ebeef1]
                    rounded-[4px]
                    after:content-['']
                    after:w-[var(--tips-progress-line-width)]
                    after:h-[inherit]
                    after:bg-[#00a807]
                "
                :style="progressLineStyle"
            ></div>
        </div>

        <div class="flex flex-col">
            <div
                v-for="tip in tips"
                :key="tip.name"
                :class="[
                    'flex items-center gap-4 h-[48px] text-[14px] leading-[130%]',
                    { 'text-[#8a8f9e]': tip.done }
                ]"
            >
                <AppIconStatusTrue
                    v-if="tip.done"
                    key="done"
                />

                <AppIconStatusEmpty
                    v-else
                    key="empty"
                />

                <span
                    key="text"
                    class="[&>a]:!inline"
                >
                    <template v-if="tip.name === 'register'">
                        {{ $t('sidebar-onboarding-2') }}
                    </template>

                    <template v-else-if="tip.name === 'set-widget'">
                        {{ $t('sidebar-onboarding-3') }}

                        <AppLink
                            :disabled-only-color="tip.done"
                            :to="{
                                name: 'p-pid-setting-channel-id-configure-widget-code-script',
                                params: {
                                    id: userStore.currentProject.channelWidget.channel_id
                                }
                            }"
                            @click="emit('close')"
                        >
                            {{ $t('widget').toLowerCase() }}
                        </AppLink>

                        {{ $t('sidebar-onboarding-4') }}
                    </template>

                    <template v-else-if="tip.name === 'personalize-widget'">
                        <AppLink
                            :disabled-only-color="tip.done"
                            :to="{
                                name: 'p-pid-setting-channel-id-configure-widget-personalization',
                                params: {
                                    id: userStore.currentProject.channelWidget.channel_id
                                }
                            }"
                            @click="emit('close')"
                        >
                            {{ $t('sidebar-onboarding-5') }}
                        </AppLink>

                        {{ $t('sidebar-onboarding-6') }}
                    </template>

                    <template v-else-if="tip.name === 'add-profile-photo'">
                        {{ $t('sidebar-onboarding-7') }}

                        <AppLink
                            :disabled-only-color="tip.done"
                            :to="{
                                name: 'p-pid-setting-account',
                                query: {
                                    action: 'change-photo'
                                }
                            }"
                            @click="emit('close')"
                        >
                            {{ $t('sidebar-onboarding-8') }}
                        </AppLink>
                    </template>

                    <template v-else-if="tip.name === 'invite-operator'">
                        {{ $t('sidebar-onboarding-9') }}

                        <AppLink
                            :disabled-only-color="tip.done"
                            :to="{ name: 'p-pid-setting-operator-create' }"
                            @click="userStore.updateOnboarding('is_operator_invite'); emit('close')"
                        >
                            {{ $t('sidebar-onboarding-10') }}
                        </AppLink>
                    </template>

                    <template v-else-if="tip.name === 'create-quick-answer'">
                        {{ $t('sidebar-onboarding-11') }}

                        <AppLink
                            :disabled="!userStore.activeSubscription"
                            :disabled-only-color="tip.done"
                            :to="{ name: 'p-pid-setting-quick-answer-create' }"
                            @click="emit('close')"
                        >
                            {{ $t('sidebar-onboarding-12') }}
                        </AppLink>
                    </template>

                    <template v-else-if="tip.name === 'create-kb-article'">
                        {{ $t('sidebar-onboarding-13') }}

                        <AppLink
                            :disabled-only-color="tip.done"
                            :to="{ name: 'p-pid-knowledge-base' }"
                            @click="emit('close')"
                        >
                            {{ $t('sidebar-onboarding-14') }}
                        </AppLink>
                    </template>

                    <template v-else-if="tip.name === 'connect-tg-or-vb'">
                        <AppLink
                            :disabled-only-color="tip.done"
                            :to="{ name: 'p-pid-setting-channel' }"
                            @click="emit('close')"
                        >
                            {{ $t('sidebar-onboarding-15') }}
                        </AppLink>

                        {{ $t('sidebar-onboarding-16') }}
                    </template>
                </span>
            </div>
        </div>
    </div>
</template>
