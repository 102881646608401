import { useUserStore } from '~/stores/user'

export type SidebarTip = {
    name: string
    done: boolean
}

export default () => {
    const userStore = useUserStore()

    const tips = computed<SidebarTip[]>(() => [
        {
            name: 'register',
            done: true
        },
        {
            name: 'set-widget',
            done: userStore.currentOperator.onboarding?.is_widget_setup || false
        },
        {
            name: 'personalize-widget',
            done: userStore.currentOperator.onboarding?.is_widget_customize || false
        },
        {
            name: 'add-profile-photo',
            done: userStore.currentOperator.onboarding?.is_operator_photo || false
        },
        {
            name: 'create-quick-answer',
            done: userStore.currentOperator.onboarding?.is_create_message_template || false
        },
        {
            name: 'create-kb-article',
            done: userStore.currentOperator.onboarding?.is_create_kb || false
        },
        {
            name: 'connect-tg-or-vb',
            done: userStore.currentOperator.onboarding?.is_create_channel || false
        },
        {
            name: 'invite-operator',
            done: userStore.currentOperator.onboarding?.is_operator_invite || false
        }
    ])

    const numberOfDoneTips = computed<number>(() => tips.value.filter(v => v.done).length)
    const progressValue = computed<string>(() => Math.round(numberOfDoneTips.value / tips.value.length * 100) + '%')

    return {
        tips,
        numberOfDoneTips,
        progressValue
    }
}
