<template>
    <AppIcon v-bind="$props">
        <rect
            x="0.5"
            y="0.5"
            width="23"
            height="23"
            rx="11.5"
            stroke="#EBEEF1"
        />
    </AppIcon>
</template>
