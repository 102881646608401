<script
    lang="ts"
    setup
>
    import createSidebarTipsContext from '~/helpers/createSidebarTipsContext'

    type Props = {
        inNavbar?: boolean
    }

    const props = defineProps<Props>()

    const { numberOfDoneTips, progressValue } = createSidebarTipsContext()
</script>

<template>
    <Transition
        name="activator-transition"
        mode="out-in"
    >
        <div
            v-if="props.inNavbar"
            :key="'small-' + numberOfDoneTips"
            class="
                flex
                w-[20px]
                h-[20px]
                rounded-[50%]
                after:w-full
                after:m-[5px]
                after:bg-white
                after:rounded-[50%]
            "
            :data-value="numberOfDoneTips"
            :style="`background: conic-gradient(#00a807 ${ progressValue }, #e3e5eb 0deg)`"
        ></div>

        <AppButtonIcon
            v-else
            :key="'default-' + numberOfDoneTips"
            class="relative"
        >
            <div class="absolute flex justify-around">
                <div
                    class="
                        flex
                        w-[40px]
                        h-[40px]
                        rounded-[50%]
                        after:content-[attr(data-value)]
                        after:flex
                        after:flex-col
                        after:justify-center
                        after:w-full
                        after:m-2
                        after:text-[16px]
                        after:font-medium
                        after:leading-[130%]
                        after:bg-white
                        after:rounded-[50%]
                    "
                    :data-value="numberOfDoneTips"
                    :style="`background: conic-gradient(#00a807 ${ progressValue }, #e3e5eb 0deg)`"
                ></div>
            </div>
        </AppButtonIcon>
    </Transition>
</template>

<style lang="sass">
    .activator-transition
        &-enter-from,
        &-leave-to
            opacity: 0
            transform: scale(0.8)

        &-enter-active,
        &-leave-active
            transform-origin: bottom
            transition: all var(--transition-default-duration-with-ease) !important

        &-enter-to,
        &-leave-from
            opacity: 1
            transform: scale(1)
</style>
