<script
    lang="ts"
    setup
>
    import type { Project } from '~/ts/types/project'

    type Props = {
        project: Project
        currentProject: Project
        showNotificationBadge: boolean
    }

    const props = defineProps<Props>()

    const isCurrentProject: boolean = props.currentProject.id === props.project.id

    const wrapperClass = computed<string>(() => {
        return `
            relative
            flex
            items-center
            min-h-[56px]
            w-full
            p-2
            rounded-[8px]
            ${ isCurrentProject ? 'bg-[#f6f7f8]' : 'bg-transparent' }
            cursor-pointer
            select-none
            hover:bg-[#f6f7f8]
        `
    })
</script>

<template>
    <div
        :data-id="props.project.id"
        :class="wrapperClass"
    >
        <div class="relative">
            <AppAvatar
                key="avatar"
                :src="props.project.logo?.thumbnails?.v120x120"
                size="40"
                class="text-[16px] font-medium leading-[130%] uppercase"
            >
                {{ props.project.name[0] }}
            </AppAvatar>

            <Transition name="scale-transition">
                <AppNotificationBadge
                    v-if="props.showNotificationBadge"
                    key="notification"
                />
            </Transition>
        </div>

        <div class="ml-4 text-[16px] font-medium leading-[130%]">
            {{ props.project.name }}
        </div>
    </div>
</template>
