<script
    lang="ts"
    setup
>
    import type { OperatorOfflineStatus } from '~/ts/types/operator'

    type Props = {
        inputModel: OperatorOfflineStatus
        inNavbar?: boolean
    }

    type Emit = {
        (event: 'close-popover'): void
        (event: 'select-option', value: OperatorOfflineStatus): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const options: OperatorOfflineStatus[] = [ null, 0, 15, 60 ]
</script>

<template>
    <div class="flex flex-col gap-1 w-full">
        <div class="px-2 text-base font-medium">
            {{ $t('my-status') }}
        </div>

        <div class="mb-2 p-2 text-[14px] leading-[130%] bg-[#F9EAD8] rounded-[8px]">
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="$t('work-status-hint')"></span>
            <!-- eslint-enable vue/no-v-html -->
        </div>

        <AppTooltip
            v-for="option in options"
            :key="option"
            :direction="props.inNavbar ? 'left' : 'right'"
            no-wrap
        >
            <template #activator="{ open: openTooltip, close: closeTooltip }">
                <TheSidebarActionSelectItem
                    :active="props.inputModel === option"
                    @mouseenter.passive="openTooltip"
                    @mouseleave.passive="closeTooltip"
                    @click="emit('select-option', option); emit('close-popover'); closeTooltip();"
                >
                    {{ $t('work-status-value-' + option) }}
                </TheSidebarActionSelectItem>
            </template>

            {{ $t('work-status-value-' + option + '-description') }}
        </AppTooltip>
    </div>
</template>
