<script
    lang="ts"
    setup
>
    type Emit = {
        (event: 'close'): void
    }

    const emit = defineEmits<Emit>()

    const widgetSupportCode = useRuntimeConfig().public.widgetSupportCode

    const widget = useWidget(widgetSupportCode)

    const pendingForLoading = ref<boolean>(false)

    widget.onSetup((sdk) => {
        sdk('setZIndex', 100)
        sdk('onBeforeWidgetClose', widget.hideWidget)
    })

    widget.onBeforeShow(() => {
        emit('close')
    })

    const openWidget = (kind: 'documentation' | 'support') => {
        if (pendingForLoading.value) {
            return
        }

        pendingForLoading.value = true

        widget.updateOptions({
            preferablyOpenChat: kind === 'support',
            preferablyOpenKB: kind === 'documentation'
        })

        widget.showWidget(kind)
    }
</script>

<template>
    <div class="flex flex-col gap-1 w-full">
        <AppDropdownItem
            :disabled="pendingForLoading"
            class="gap-4 !font-medium"
            @click="openWidget('documentation')"
        >
            <AppIconSearch size="20"/>

            {{ $t('documentation') }}
        </AppDropdownItem>

        <AppDropdownItem
            :disabled="pendingForLoading"
            class="gap-4 !font-medium"
            @click="openWidget('support')"
        >
            <AppIconKeyboard size="20"/>

            {{ $t('ask-support') }}
        </AppDropdownItem>
    </div>
</template>
