<script
    lang="ts"
    setup
>
    type Props = {
        active?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        active: false
    })

    const containerClass = computed<string>(() => {
        let classes = `
            flex
            items-center
            w-full
            p-2
            text-[14px]
            font-medium
            leading-[130%]
            rounded-[8px]
            cursor-pointer
            select-none
            hover:bg-[#f6f7f8]
            hover:[&_svg>path]:fill-fill-black
        `

        if (props.active) {
            classes += ' bg-[#f6f7f8] [&_svg>path]:fill-black'
        }

        return classes
    })
</script>

<template>
    <div :class="containerClass">
        <slot/>
    </div>
</template>
